<template lang="pug">
  <div class="mobile-notice">
    <div class="mobile-notice-container">
      <h1 class="heading-22">Sorry..</h1>
      <div class="text-block-47">Open this website on a browser on your computer to continue.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
};
</script>

<style src="./TopBar.scss" lang="scss" scoped />
