<template lang="pug">
  #configurator
    #area_left.area-left
      //- #dropzone.dropzone
      #area_3d.area3d
        canvas#overflow-path
    div.option-list-wrapper
      base-card.config(title="Configurator")
        div.config__list
          ConfigList(
            :showMeasureStatus.sync="canvasShowStatus"
            :model="model"
            @updateImageURL="updateImageURL"
          )
      div(style="margin-top: 30px")
        a.flex-center(href='tutorial') Need help? Click here for a tutorial
</template>

<script
  src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js?site=5c517fa72dd88f2f86a54ec2"
  type="text/javascript"
  integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo="
  crossorigin="anonymous"
></script>
<script>
// import * as THREE from '@/vendors/lib/three.min';
import $ from 'jquery';
import '@/vendors/lib/jquery-3.4.1.min';
import '@/vendors/lib/jquery-ui.min';
import '@/vendors/lib/jquery.ui.touch-punch.min';
import '@/vendors/lib/jquery.loading.min';
import ConfigList from './components/ConfigList';
import View3DModel from './configurator/configurator';

export default {
  name: 'Configurator',
  components: {
    ConfigList,
  },
  data() {
    return {
      uploadedImage: null,
      toggleStatus: {},
      canvasId: 'overflow-path',
      canvasShowStatus: false,
      model: View3DModel,
    };
  },
  computed: {
    getCanvasBgImage() {
      return {
        backgroundImage:
        !this.toggleStatus.CANVAS_OVERFLOW_PATH
          ? `url(${this.uploadedImage})`
          : '',
      };
    },
  },
  mounted() {
    this.model.fnInitializeLayoutDocument();
  },
  methods: {
    updateImageURL(updatedVal) {
      this.uploadedImage = updatedVal;

      $("#area_3d").loading();
      const file = document.getElementById("myInput").files[0];
      const reader = new FileReader();
      reader.onloadend = function () {
        $("#area_3d").css("background-image", "url(" + reader.result + ")");
        $("#area_3d").loading("stop");
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>

<style src="./Configurator.scss" lang="scss" />
