<template lang="pug">
  header
    div.navbar-container
      div.nav-item
        a.logo(href='homepage')
      div.nav-item
        a.nav-text(href='contact') Contact us
</template>

<script>

export default {
  name: 'Header',
  methods: {
    gotoPage() {
      // eslint-disable-next-line no-alert
      alert('clicked');
    },
  },
};
</script>

<style src="./Header.scss" lang="scss" scoped />
