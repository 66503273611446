import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyDDDPVQi-e9abAaWsGn49214XINr0PPGRo',
  authDomain: 'therasys-configurator.firebaseapp.com',
  projectId: 'therasys-configurator',
  storageBucket: 'therasys-configurator.appspot.com',
  messagingSenderId: '170781222146',
  appId: '1:170781222146:web:1e464705ff3bd8e48f0e1b',
  measurementId: 'G-DMFLVN0P6W',
};

firebase.initializeApp(firebaseConfig);
