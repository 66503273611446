<template lang="pug">
  #container
    Header
    TopBar
    #content
      router-view/
      //- div(style="word-break: break-all;") {{ user }}
    Footer
</template>

<script>
import Header from '@/components/layout/Header';
import Footer from '@/components/layout/Footer';
import TopBar from '@/components/layout/TopBar';

export default {
  name: 'Container',
  components: {
    Header,
    TopBar,
    Footer,
  },
};
</script>

<style src="./Container.scss" lang="scss" scoped />
