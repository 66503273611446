/* eslint-disable max-len */
<template lang="pug">
  <div class="footer">
    <div class="footer__wrapper">
      <div id="w-node-df4541a180fa-85fa9b29" class="footer__credits">
        <a href="https://therasys.r3volutiond.com" class="footer__logo w-nav-brand">
        <img src="~@/assets/images/logo-white.png" loading="lazy" width="200"></a>
        <div class="footer__copyright">© Copyright 2021 r3volutionD AG.</div>
        <div class="footer__copyright">All rights reserved.</div>
      </div>
      <div class="footer__links-wrapper">
        <h6 class="footer__heading">Company</h6>
        <a href="https://therasys.r3volutiond.com" class="footer__link">Therasys</a>
        <a href="https://therasys.r3volutiond.com/contact-us" class="footer__link">Contact us</a>
        <a href="http://www.r3volutiond.com" target="_blank" class="footer__link">About us</a>
      </div>
      <div class="footer__links-wrapper">
        <h6 class="footer__heading">Legal</h6>
        <a href="https://therasys.r3volutiond.com/legal-notice" class="footer__link">Legal Notice</a>
        <a href="https://therasys.r3volutiond.com/terms" class="footer__link">Terms of Use</a>
        <a href="https://therasys.r3volutiond.com/privacy-policy" class="footer__link">Privacy Policy</a>
        <a href="https://therasys.r3volutiond.com/cookies-policy" class="footer__link">Cookies Policy</a>
      </div>
      <div id="w-node-df4541a1811d-85fa9b29" class="footer__sm">
        <h6 class="footer__heading">Follow us</h6>
        <div class="footer__sm-wrapper">
          <a href="https://www.instagram.com/r3volutiond.ag" target="_blank" class="footer__sm-link w-inline-block">
            <div class="footer__sm-icon w-embed">
            <img src="~@/assets/images/instagram.svg">
            </div>
          </a>
          <a href="https://www.facebook.com/r3volutionD/" target="_blank" class="footer__sm-link w-inline-block">
            <div class="footer__sm-icon w-embed">
            <img src="~@/assets/images/facebook.svg">
            </div>
          </a>
          <a href="https://www.linkedin.com/company/28518319" target="_blank" class="footer__sm-link w-inline-block">
            <div class="footer__sm-icon w-embed">
            <img src="~@/assets/images/linkedin.svg">
            </div>
          </a>
          <a href="https://twitter.com/r3volutiondag" target="_blank" class="footer__sm-link w-inline-block">
            <div class="footer__sm-icon w-embed">
            <img src="~@/assets/images/twitter.svg">
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style src="./Footer.scss" lang="scss" scoped />
