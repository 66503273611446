import Vue from 'vue';
import VueRouter from 'vue-router';

import Container from '@/views/Container';
import Configurator from '@/views/Configurator';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Therasys',
    component: Container,
    redirect: { name: 'Configurator' },
    children: [
      {
        path: 'configurator',
        name: 'Configurator',
        component: Configurator,
      },
    ],
  },
  {
    path: '/contact/:filename',
    beforeEnter(to) {
      window.location.href = `https://therasys.r3volutiond.com/contact-us?filename=${to.params.filename}`;
    },
  },
  {
    path: '/homepage',
    beforeEnter() {
      window.location.href = 'https://therasys.r3volutiond.com';
    },
  },
  {
    path: '/tutorial',
    beforeEnter() {
      window.location.href = 'https://youtu.be/CPOCFuWETwA';
    },
  },
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
