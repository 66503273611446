<template lang="pug">
  .config-list
    ConfigListItem(
      title="Shaft"
      max-height="70px"
      :flip="showStatus[TYPES.SHAFT]"
      @onClick="() => toggleStatus(TYPES.SHAFT)"
    )
      label.my-10.p-0 Length in mm
      div.slider(id="slider_shaft_length")
    ConfigListItem(
      title="Thread"
      max-height="70px"
      :flip="showStatus[TYPES.THREAD]"
      @onClick="() => toggleStatus(TYPES.THREAD)"
    )
      label.my-10.p-0 Length in mm
      div.slider(id="slider_thread_length")
    ConfigListItem(
      id="slider_num_porse"
      title="Pores"
      max-height="139px"
      :flip="showStatus[TYPES.PORES]"
      @onClick="() => toggleStatus(TYPES.PORES)"
    )
      label.my-10.p-0 Number of Pores
      div.slider(id="slider_num_porse")
      label.my-10.p-0 Size of Pores / Canal Ø in mm
      div.radio-div
        label(class="radio-button" for="size-1") S / 1.2
        input(type="radio" name="size" id="size-1" checked value="s")
        label(class="radio-button" for="size-2") M / 1.4
        input(type="radio" name="size" id="size-2" value="m")
        label(class="radio-button" for="size-3") L / 1.6
        input(type="radio" name="size" id="size-3" value="l")
    ConfigListItem(
      title="Scale"
      max-height="190px"
      :flip="showStatus[TYPES.SCALE]"
      @onClick="() => toggleStatus(TYPES.SCALE)"
    )
      base-image-upload(
        id="myInput"
        @update="val => $emit('updateImageURL', val)"
      )
      base-button.scale-button(
        :label="getMeasureLabel"
        :focused="showMeasureStatus ? true : false"
        full-width
        outlined
        @onClick="toggleMeasure"
      )
        template(v-if="showMeasureStatus"): v-icon(name="check" scale="1.5" class="button-focused")
      div.scale-measure(:class="{ hide: !showMeasureStatus }")
        label Length Input X-Ray:
        base-input(
          id="measure-length"
          placeholder="Enter length in mm"
          full-width
          @onKeyUp="setScrewSize"
        )
    div.info
      span Length Screw:
      span#screw-length 50mm
    div(style="margin-top: 20px")
      base-button(
        id="btn_export"
        :label="uploadButtonLabel"
        @onClick="exportProduct"
      )
</template>

<script>
import VueSlider from 'vue-slider-component';
import firebase from 'firebase';
import $ from 'jquery';
import '@/vendors/lib/jquery-ui.min';
import { bone3DViewer } from '../../configurator/configurator';
import ConfigListItem from '../ConfigListItem';
import {
  commonSlideOption,
  poreTypeLabels,
} from '../../constants';
import {
  TYPES,
  PORE_SIZE,
} from '../../types';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'ConfigList',
  components: {
    VueSlider,
    ConfigListItem,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    model: Object,
    showMeasureStatus: {
      type: Boolean,
      default: false,
    },
    updateImageURL: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      TYPES,
      PORE_SIZE,
      poreTypeLabels,

      uploadedImage: '',
      showStatus: {
        [TYPES.SCALE]: true,
      },

      // 3D printed model instance
      productModel: this.model,

      // sliders
      shaft: 0,
      thread: 0,
      pores: 0,
      shaftOption: {
        ...commonSlideOption,
      },
      threadOption: {
        ...commonSlideOption,
      },
      poresOption: {
        ...commonSlideOption,
      },

      uploadButtonLabel: 'Request sample',
    };
  },
  computed: {
    getMeasureLabel() {
      return this.showMeasureStatus
        ? 'Finish X-Ray Measure'
        : 'Start X-Ray Measure';
    },
  },
  mounted() {
    this.initModel();
    this.initSlider();
  },
  methods: {
    initModel() {
      if (!this.productModel) {
        // eslint-disable-next-line new-cap
        this.productModel = new bone3DViewer();
      }
    },
    initSlider() {
      const {
        size_shaft: minSizeShaft,
        size_thread: minSizeThread,
      } = this.productModel;

      this.shaft = minSizeShaft;
      this.shaftOption = {
        ...this.shaftOption,
        min: minSizeShaft,
        max: minSizeShaft + 25,
      };

      this.thread = minSizeThread;
      this.threadOption = {
        ...this.threadOption,
        min: minSizeThread,
        max: minSizeThread + 20,
      };

      this.pores = 0;
      this.poresOption = {
        ...this.poresOption,
        min: 0,
        max: 100,
      };
    },
    toggleStatus(type) {
      const status = this.showStatus[type];

      this.showStatus = {
        ...this.showStatus,
        [type]: (status !== undefined) ? !status : true,
      };
    },
    toggleMeasure() {
      const updatedMeasureStatus = !this.showMeasureStatus;

      this.productModel.ismeasure = updatedMeasureStatus;
      this.$emit('update:showMeasureStatus', updatedMeasureStatus);

      if (updatedMeasureStatus) {
        this.model.myJs3DViewer.ismeasure = true;
        $('#overflow-path').css('display', 'block');
        $('#checkbox-label').text('Finish X-Ray Measure');
      } else {
        this.model.myJs3DViewer.ismeasure = false;
        $('#overflow-path').css('display', 'none');
        $('#checkbox-label').text('Start X-Ray Measure');
      }
    },
    setScrewSize(val) {
      this.productModel.realScrewSize = val;
      this.productModel.updateCamera();
    },
    updatePoreSize(size) {
      this.productModel.fnUpdatePorsSize(size);
    },
    updateMeasure(updatedVal) {
      this.productModel.ismeasure = updatedVal;
    },
    updateSlider(value, type) {
      switch (type) {
        case TYPES.SHAFT:
          this.productModel.fnChangeShaft(value);
          break;
        case TYPES.THREAD:
          this.productModel.fnChangeThread(value);
          break;
        case TYPES.PORES:
          this.productModel.fnUpdatePorsNum(value);
          break;
        default:
          break;
      }
    },
    exportProduct() {
      // window.location.replace(`contact?, ${this.productModel.exportName()}`);
      // TODO
      // $('#btn_export').text('Processing..');
      // this.uploadButtonLabel = 'Processing...';

      // setTimeout(() => {
      //   this.productModel.fnExportSTL(
      //     'scene.stl',
      //     this.saveArrayBuffer,
      //   );
      // }, 0);
    },
    saveArrayBuffer(buffer, fileName) {
      this.save(
        new Blob([buffer], {
          type: 'application/json',
        }),
        fileName,
      );
    },
    save(blob) {
      const exportName = this.productModel.exportName();
      this.uploadModel(blob, exportName);
    },
    uploadModel(file, filename) {
      // const db = firebase.firestore();
      // Get the current user
      const user = firebase.auth().currentUser;
      // Create a root reference
      const storageRef = firebase.storage().ref();
      // Create the file metadata
      const metadata = {
        contentType: 'application/vnd.ms-pki.stl',
      };
      // Upload file and metadata to the object
      const uploadTask = storageRef
        .child(`users/${user.uid}/cart/${filename}.stl`)
        .put(file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          // Get task progress,
          // including the number of bytes uploaded and
          // the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
          // TODO
          // $('#btn_export').text(`Uploading ${Math.round(progress)}%`);

          this.uploadButtonLabel = `Uploading ${Math.round(progress)}%`;
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
            default:
              break;
          }
        },
        (error) => {
          // TODO
          // $('#btn_export').text('Done');

          this.uploadButtonLabel = 'Request Sample';

          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              // eslint-disable-next-line no-alert
              alert('Error. No Permission.');
              break;
            case 'storage/canceled':
              // User canceled the upload
              // eslint-disable-next-line no-alert
              alert('Upload Cancelled.');
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              // eslint-disable-next-line no-alert
              alert('Unknown Error.');
              break;
            default:
              break;
          }
        },
        () => {
          this.uploadButtonLabel = 'Success';
          window.location.replace('contact');

          // console.log('FILENAME: ', filename);
          // // Upload completed successfully, now we can get the download URL
          // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          //   db.collection('users')
          //     .doc(user.uid)
          //     .collection('cart')
          //     .add({
          //       product_id: filename,
          //       downloadurl: downloadURL,
          //       timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          //       standard: false,
          //     })
          //     .then(() => {
          //       console.log('Document successfully written!');
          //       window.location.replace('/dashboard/option');
          //     })
          //     .catch((error) => {
          //       console.error('Error writing document: ', error);
          //       // eslint-disable-next-line no-alert
          //       alert('Something went wrong. Please try again.');
          //     });
          // });
        },
      );
    },
  },
};
</script>

<style src="./ConfigList.scss" lang="scss" scoped />
